import { createRouter, createWebHistory } from 'vue-router'
function loadPage (pagePath) {
  return () => import(`@/${pagePath}`);
}

const routes = [
  {
    path: '/',
    name: 'Main_Page',
    component: loadPage('views/Main_Page.vue')
  },
  {
    path: '/',
    name: 'Main_Shop',
    component: loadPage('views/Main_Shop.vue')
  },
  {
    path: '/contact',
    name: 'Main_Contact',
    component: loadPage('views/Main_Contact.vue')
  },
  {
    path: '/blog',
    name: 'Main_Blog',
    component: loadPage('views/Main_Blog.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
